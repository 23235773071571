<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0">
        <div class="row praxisBackground text-Dunkelgrau">
          <div class="col-md-5 d-flex align-items-center ps-5">
            <img
              src="@/assets/HerzlichWillkommen.png"
              class="img-fluid mx-auto d-block HerzlichWillkommen m-2"
              v-bind:alt="pic"
            />
          </div>

          <div class="col-md-7 p-0">
            <img
              src="@/assets/empfang.jpg"
              class="img-fluid mx-auto d-block"
              v-bind:alt="pic"
            />
          </div>
        </div>
        <div class="row bg-Hellgrau">
          <div class="col-md-10 offset-md-1 mt-5 bg-Hellgrau">
            <div class="row bg-Hellgrau">
              <div class="col-md botsch  bg-Weiss ">
                <div class="bg-Weiss textweiss">
                  <div class="p-0">
                    <h3 class="text-Rot fw-bold m-0">ALMUT BOTSCH</h3>
                    <p class="text-Rot fw-bold pt-0 p-0 m-0">——————————</p>
                    <p class="m-0 praxisFfA">Fachärztin für Allgemeinmedizin</p>
                  </div>
                  <p class="pt-4 kastentext">
                    Nach meinem Studium der Humanmedizin in Leipzig machte ich
                    erste klinische Erfahrungen im Dietrich-Bonhoeffer-Klinikum
                    Neubrandenburg. 2012 sind wir nach Saalfeld gezogen, wo ich
                    zunächst in der Thüringen Klinik als Assistenzärztin tätig
                    war. Im Anschluss habe ich im Rahmen meiner
                    Facharztweiterbildung verschiedene Praxen in Saalfeld und
                    Unterwellenborn kennengelernt. Seit 2018 bin ich Fachärztin
                    für Allgemeinmedizin und arbeitete als angestellte Ärztin in
                    der Praxis von Frau Dipl. med. Schönhals. Im April 2021 habe
                    ich die Praxis von Frau Schönhals übernommen und führe diese
                    als Praxis für Allgemeinmedizin fort.
                  </p>
                </div>
              </div>

              <div class="col-md p-5 bg-Hellgrau">
                <img
                  src="@/assets/Botsch.jpg"
                  class="img-fluid"
                  v-bind:alt="pic"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-Hellgrau">
          <div class="col-md-10 offset-md-1 mt-5">
            <h1 class="fw-bold" style="text-align: center;">UNSERE TECHNISCHE AUSSTATTUNG</h1>
          </div>
        </div>
        <div class="row bg-Hellgrau">
          <div class="col-md-10 offset-md-1 bg-Hellgrau">
            <div class="row bg-Hellgrau">
              
              <div class="col-md  bg-Weiss center bildKasten">
              <h1 class="fw-bold text-Dunkelgrau pb-3 fuerKlein">Sonografie</h1>
                <img
                  src="@/assets/sono.jpg"
                  class="img-fluid imgRoundet mx-auto d-block"
                  v-bind:alt="pic"
                />
              </div>
              <div class="col-md botsch  bg-Weiss ">
                <div class="bg-Weiss textweiss">
                  <h1 class="fw-bold text-Dunkelgrau pb-3 fuerGross">Sonografie</h1>
                  <p class="p-0 kastentext">
                    Die Untersuchung der inneren Organe mithilfe eines
                    Ultraschallgerätes ist ein schnelles und aussagekräftiges
                    diagnostisches Verfahren bei verschiedenen Beschwerden und
                    Veränderungen. Es entsteht keine Strahlenbelastung.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row bg-Hellgrau">
          <div class="col-md-10 offset-md-1 bg-Hellgrau">
            <div class="row bg-Hellgrau">
              

              <div class="col-md m-md-5 bg-Weiss center bildKasten fuerKlein">
                  <h1 class="fw-bold text-Dunkelgrau pb-3">EKG</h1>
                <img
                  class="img-fluid imgRoundet mx-auto d-block"
                  src="@/assets/ekg.jpg"
                  v-bind:alt="pic"
                />
              </div>

              <div class="col-md botsch  bg-Weiss ">
                <div class="bg-Weiss textweiss">
                  <h1 class="fw-bold text-Dunkelgrau pb-3 fuerGross">EKG</h1>
                  <p class="pt-0 kastentext">
                    Ein Elektrokardiogramm (EKG) ist eine temporäre Aufzeichnung
                    der elektrischen Impulse der Herzmuskulatur. Die Erstellung
                    eines EKGs ist eine schmerzfreie, sehr einfach durchführbare
                    und wertvolle Untersuchungsmethode. Es ist das wichtigste
                    Instrument, um Herzrhythmusstörungen und Herzinfarkte zu
                    diagnostizieren.
                  </p>
                </div>
              </div>

              <div class="col-md m-md-5 bg-Weiss center bildKasten fuerGross">
                <img
                  class="img-fluid imgRoundet mx-auto d-block"
                  src="@/assets/ekg.jpg"
                  v-bind:alt="pic"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row bg-Hellgrau">
          <div class="col-md-10 offset-md-1 bg-Hellgrau">
            <div class="row bg-Hellgrau">
              <div class="col-md m-md-5 bg-Weiss center bildKasten">
                  <h1 class="fw-bold text-Dunkelgrau pb-3 fuerKlein">
                    Langzeit Blutdruck
                  </h1>
                <img
                  src="@/assets/langzeit.jpg"
                  class="img-fluid imgRoundet mx-auto d-block"
                  v-bind:alt="pic"
                />
              </div>
              <div class="col-md botsch  bg-Weiss ">
                <div class="bg-Weiss textweiss">
                  <h1 class="fw-bold text-Dunkelgrau pb-3 fuerGross">
                    Langzeit Blutdruck
                  </h1>
                  <p class="p-0 kastentext">
                    Eine Blutdruckmessung ist eine wichtige Routineuntersuchung.
                    Durch die wiederholte Messung über 24 Stunden mittels
                    Langzeit-Blutdruckmessung lassen sich Rückschlüsse auf
                    Schweregrad und eventuelle Ursachen des Bluthochdruckes
                    ziehen. Aufgrund der Langzeitmessung können Einflussfaktoren
                    wie Stress durch den Arztbesuch oder unterschiedliche Tag-
                    und Nachtwerte besser beobachtet werden. Sie ist außerdem
                    ein wichtiges Hilfsmittel zur Überprüfung des
                    Therapieerfolges.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Praxis",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
