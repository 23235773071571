<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0">
        <div class="row bg-Weiss p-0">
          <div class="col-md-12 p-0 mt-md-0">
            <img
              src="@/assets/aussenPanorama.jpg"
              class="img-fluid mx-auto d-block"
              v-bind:alt="pic"
            />
          </div>
        </div>

        <div class="row pt-2 mb-0 mt-0 bg-Dunkelgrau">
          <div class="col-md-6 offset-md-3 title mt-5 bg-Hellgrau">
            <div class="ps-4">
              <h1 class="text-Dunkelgrau fw-bold mt-5">KONTAKT</h1>
            </div>
            <div class="ps-4">
              Sie haben Fragen oder Wünsche? Das Team der FA Almut Botsch ist
              gern für Sie da!
            </div>
            <div class="container py-4">
              <!-- Bootstrap 5 starter form 
              <form id="contactForm">-->
                <!-- Name input -->
                <div class="mb-3">
                  <label class="form-label" for="name">Name*</label>
                  <input
                    class="form-control"
                    type="text"
                    name="name"
                    v-bind:class="background.name"
                    placeholder="Mein Name"
                    v-model="mail.name"
                    required
                  />
                
                </div>

                <!-- Email address input -->
                <div class="mb-3">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-label" for="emailAddress"
                        >Email Adresse*</label
                      >
                      <input
                        class="form-control"
                        type="text"
                        name="email"
                        placeholder="max@example.de"
                        v-model="mail.email"
                        v-bind:class="background.email"
                        required
                      />
                      <div
                        class="invalid-feedback"
                        data-sb-feedback="emailAddress:required"
                      >
                        Email Address it notwendig.
                      </div>
                      <div
                        class="invalid-feedback"
                        data-sb-feedback="emailAddress:email"
                      >
                        Email Address Email ist ungültig.
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label" for="emailAddress"
                        >Telefon</label
                      >
                      <input
                        class="form-control"
                        type="text"
                        name="telefon"
                        placeholder="03671 12 34 56"
                        v-model="mail.telefon"
                        v-bind:class="background.telefon"
                      />
                    </div>
                  </div>
                </div>

                <!-- Message input -->
                <div class="mb-3">
                  <label class="form-label" for="message">Anfrage*</label>
                  <textarea
                    class="form-control"
                    id="message"
                    type="text"
                    placeholder="Ihre Nachricht an uns"
                    style="height: 10rem"
                    v-model="mail.message"
                    v-bind:class="background.message"
                    required
                  ></textarea>
                  <div
                    class="invalid-feedback"
                    data-sb-feedback="message:required"
                  >
                    Message ist notwendig.
                  </div>
                </div>

                <!-- Form submissions success message -->
                <div class="d-none" id="submitSuccessMessage">
                  <div class="text-center mb-3">Formular wurde gesendet!</div>
                </div>

                <!-- Form submissions error message -->
                <div class="d-none" id="submitErrorMessage">
                  <div class="text-center text-danger mb-3">
                    Fehler beim Senden des Formulars!<br />
                    Sie können uns auch unter der Rufnummer: 03671 / 64 13 11
                    erreichen
                  </div>
                </div>

                <div class="col-md-10 mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="andere"
                    v-model="mail.datenschutz"
                    v-bind:class="background.datenschutz"
                    required
                  />
                  <label class="form-check-label" for="andere"
                    >&nbsp; Ich habe die
                    <a href="/Datenschutz">Datenschutzerklärung</a> zur Kenntnis
                    genommen.
                  </label>
                  <label class="form-check-label inputerror"> </label>
                </div>

                <!-- Form submit button -->
                <div class="d-grid">
                  <button class="btn bg-Rot text-Weiss btn-lg"
                          @click="sendMail()"
                           type="submit">
                    Senden
                  </button>
                </div>
              <!--</form>-->
              <div class="col-md-12 mb-3">
                <div class="row">
                  <div class="col-md-6 text-center">
                    <div class="row ms-3 me-3 mb-0 pb-0">
                      <div
                        class="col-12 mb-0 pb-0 h2 text-Weiss text-Shadow fw-bold"
                      >
                        ARZTPRAXIS
                      </div>
                    </div>
                    <div class="row pb-0">
                      <div class="col-12 mb-0 pb-0 h4 text-Rot fw-bold">
                        ALMUT BOTSCH
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 praxisFfA text-Dunkelgrau">
                        Fachärztin für Allgemeinmedizin
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 text-end">
                    <div class="row">
                      <div class="col-md-10">
                        <a
                          title="Adresse"
                          href="https://goo.gl/maps/moJfk5jzZpRWkE4r5"
                          target="_blank"
                          class="text-Schwarz"
                        >
                          Rathenaustr.11</a
                        ><br />
                        07318 Saalfeld
                      </div>

                      <div class="col-md-2">
                        <a
                          title="Adresse"
                          href="https://goo.gl/maps/moJfk5jzZpRWkE4r5"
                          target="_blank"
                        >
                          <i
                            style="font-size: 18px"
                            class="fas fa-map-marker-alt text-Schwarz"
                          ></i
                        ></a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <a class="text-Schwarz" href="tel:03671 641311"
                          >03671 / 64 13 11</a
                        >
                      </div>
                      <div class="col-md-2">
                        <i class="fas fa-phone" style="font-size: 18px"></i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">03671 / 61 45 05</div>
                      <div class="col-md-2">
                        <i class="fas fa-print" style="font-size: 18px"></i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <a
                          class="text-Schwarz"
                          href="mailto:praxis-botsch-slf@web.de"
                          >praxis-botsch-slf@web.de</a
                        >
                      </div>
                      <div class="col-md-2">
                        <i
                          class="fas fas fa-envelope"
                          style="font-size: 18px"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-2 mb-0 mt-0 bg-Dunkelgrau">
          <div class="col-md-6 offset-md-3 title mt-5 bg-Hellgrau"></div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import axios from "axios";

export default {
  name: "Praxis",

  data() {
    return {
      newSite: null,
      mail: {
        name: "",
        email: "",
        message: "",
        telefon: "",
        datenschutz: false,
        gesendet: "",
      },
      background: {
        name: "",
        email: "",
        message: "",
        telefon: "",
        datenschutz: "",
      },
    };
  },
  components: {
    Layout,
  },
  methods: {
    checkEingaben() {
      let re = true;
      if (this.mail.name == "") {
        this.background.name = "border border-2 border-danger";
        re = false;
      } else {
        this.background.name = "border border-2 border-success";
      }
      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }
      if (this.mail.message == "") {
        this.background.message = "border border-2 border-danger";
        re = false;
      } else {
        this.background.message = "border border-2 border-success";
      }
      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },
    sendMail() {
      if (this.checkEingaben()) {
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "praxis-botsch-slf@web.de",
          Name: this.mail.name,
          Email: this.mail.email,
          Grund:"Kontaktaufnahme",
          Text: this.mail.message,
          Datenschutz: this.mail.datenschutz,
        };
        let mail = this.mail;
        let background = this.background;
        axios
          .post("https://kito-design.de/mailrelay/post_attachment.php", payload, axiosConfig)
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Ihre Kontaktdaten wurden erfolgreich versendet";
            mail.name = "";
            background.name = "";
            mail.email = "";
            background.email = "";
            mail.message = "";
            background.message = "";
            mail.datenschutz = false;
            background.datenschutz = "";
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
*:focus {
  outline: none;
}
body {
  font: 14px/21px "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode",
    sans-serif;
}
.contact_form h2,
.contact_form label {
  font-family: Georgia, Times, "Times New Roman", serif;
}
.form_hint,
.required_notification {
  font-size: 11px;
}
.contact_form ul {
  list-style-type: none;
  list-style-position: outside;
  margin: 0px;
  padding: 0px;
}
.contact_form li {
  padding: 12px;
  border-bottom: 1px solid #eee;
  position: relative;
}
.contact_form li:first-child,
.contact_form li:last-child {
  border-bottom: 1px solid #777;
}
.contact_form h2 {
  margin: 0;
  display: inline;
}
.required_notification {
  color: #d45252;
  margin: 5px 0 0 0;
  display: inline;
  float: right;
}
.contact_form label {
  width: 150px;
  margin-top: 3px;
  display: inline-block;
  padding: 3px;
}
.contact_form input {
  height: 20px;
  width: 220px;
  padding: 5px 8px;
}
.checkbox {
  width: 20px !important;
}
.checkboxLabel {
  width: 355px !important;
}
.underline {
  text-decoration: underline;
}
.contact_form textarea {
  padding: 8px;
  width: 450px;
}
.contact_form button {
  margin-left: 156px;
}
.contact_form input,
.contact_form textarea {
  border: 1px solid #aaa;
  box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;
  border-radius: 2px;
}
.contact_form input:focus,
.contact_form textarea:focus {
  background: #fff;
  border: 1px solid #555;
  box-shadow: 0 0 3px #aaa;
}
/* Button Style */
button.submit {
  background-color: #68b12f;
  background: -webkit-linear-gradient(top, #68b12f, #50911e);
  background: -moz-linear-gradient(top, #68b12f, #50911e);
  background: -ms-linear-gradient(top, #68b12f, #50911e);
  background: -o-linear-gradient(top, #68b12f, #50911e);
  background: linear-gradient(top, #68b12f, #50911e);
  border: 1px solid #509111;
  border-bottom: 1px solid #5b992b;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: inset 0 1px 0 0 #9fd574;
  -webkit-box-shadow: 0 1px 0 0 #9fd574 inset;
  -moz-box-shadow: 0 1px 0 0 #9fd574 inset;
  -ms-box-shadow: 0 1px 0 0 #9fd574 inset;
  -o-box-shadow: 0 1px 0 0 #9fd574 inset;
  color: white;
  font-weight: bold;
  padding: 6px 20px;
  text-align: center;
  text-shadow: 0 -1px 0 #396715;
}
button.submit:hover {
  opacity: 0.85;
  cursor: pointer;
}
button.submit:active {
  border: 1px solid #20911e;
  box-shadow: 0 0 10px 5px #356b0b inset;
  -webkit-box-shadow: 0 0 10px 5px #356b0b inset;
  -moz-box-shadow: 0 0 10px 5px #356b0b inset;
  -ms-box-shadow: 0 0 10px 5px #356b0b inset;
  -o-box-shadow: 0 0 10px 5px #356b0b inset;
}
.contact_form input:focus,
.contact_form textarea:focus {
  /* add this to the already existing style */
  padding-right: 70px;
}
.contact_form input,
.contact_form textarea {
  /* add this to the already existing style */
  -moz-transition: padding 0.25s;
  -webkit-transition: padding 0.25s;
  -o-transition: padding 0.25s;
  transition: padding 0.25s;
}
.contact_form input,
.contact_form textarea {
  padding-right: 30px;
}
input:required,
textarea:required {
  background: #fff no-repeat 98% center;
}
::-webkit-validation-bubble-message {
  padding: 1em;
}
.contact_form input:focus:invalid,
.contact_form textarea:focus:invalid {
  /* when a field is considered invalid by the browser */
  background: #fff no-repeat 98% center;
  box-shadow: 0 0 5px #d45252;
  border-color: #b03535;
}
.contact_form input:required:valid,
.contact_form textarea:required:valid {
  /* when a field is considered valid by the browser */
  background: #fff no-repeat 98% center;
  box-shadow: 0 0 5px #5cd053;
  border-color: #28921f;
}
.form_hint {
  background: #d45252;
  border-radius: 3px 3px 3px 3px;
  color: white;
  margin-left: 8px;
  padding: 1px 6px;
  z-index: 999; /* hints stay above all other elements */
  position: absolute; /* allows proper formatting if hint is two lines */
  display: none;
}
.form_hint::before {
  content: "\25C0"; /* left point triangle in escaped unicode */
  color: #d45252;
  position: absolute;
  top: 1px;
  left: -6px;
}
.contact_form input:focus + .form_hint {
  display: inline;
}
.contact_form input:required:valid + .form_hint {
  background: #28921f;
} /* change form hint color when valid */
.contact_form input:required:valid + .form_hint::before {
  color: #28921f;
} /* change form hint arrow color when valid */
</style>
