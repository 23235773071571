<template class="">
  <div class="row footer pt-4 pb-4 m-0">
   <div class="col-md-2 col-sm-12 offset-md-8 offset-sm-0 "> 
    <router-link
              to="/Datenschutz"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Datenschutz' ? 'navFooter-active' : 'navFooter-deactiv',
              ]"
              > Datenschutz</router-link></div>
   <div class="col-md-2 col-sm-12 offset-0"> 
     <router-link
              to="/Impressum"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Impressum' ? 'navFooter-active' : 'navFooter-deactiv',
              ]"
              > Impressum</router-link></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
  methods: {},
  data() {
    return {
      activesite: "",
    };
  },
  created() {},
};
</script>

<style scoped></style>
