<template>
  <Layout>
    <template #default>
      <div class="container-fluid m-0 p-0">
        <div class="row bg-Weiss">
          <div class="col-md-12 text-end">
            <img
              src="@/assets/termine.jpg"
              class="img-fluid mx-auto d-block"
              v-bind:alt="pic"
            />
          </div>
        </div>

        <div class="row bg-Dunkelgrau">
          <div class="col-md-10 offset-md-1 bg-Hellgrau">
            <div class="row">
              <div class="col-md-12 kastengrau">
                <h1 class="fw-bold text-Dunkelgrau text-uppercase">
                  Vereinbaren Sie einen Termin
                </h1>
                <p></p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 offset-md-3 title bg-Hellgrau">
                <div class="ps-4">
                  <h1 class="text-Dunkelgrau fw-bold"></h1>
                </div>
                <div class="ps-4">
                  <p>
                    Für einen neuen Termin können Sie uns jederzeit eine Mail
                    über dieses Formular senden oder uns während der
                    Sprechzeiten anrufen.
                  </p>
                  Das Team der FA Almut Botsch ist gern für Sie da!
                </div>
                <div class="container py-4">
                  <!-- Bootstrap 5 starter form -->
                  <div id="contactForm">
                    <!-- Name input -->
                    <div class="mb-3">
                      <label class="form-label" for="name">Name*</label>
                      <input
                        class="form-control"
                        type="text"
                        name="name"
                        v-bind:class="background.name"
                        placeholder="Mein Name"
                        v-model="mail.name"
                        required
                      />
                      <div
                        class="invalid-feedback"
                        data-sb-feedback="name:required"
                      >
                        Name ist notwendig.
                      </div>
                    </div>

                    <!-- Email address input -->
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-md-6">
                          <label class="form-label" for="emailAddress"
                            >Email Adresse*</label
                          >
                          <input
                            class="form-control"
                            type="text"
                            name="email"
                            placeholder="max@example.de"
                            v-model="mail.email"
                            v-bind:class="background.email"
                            required
                          />
                          <div
                            class="invalid-feedback"
                            data-sb-feedback="emailAddress:required"
                          >
                            Email Address it notwendig.
                          </div>
                          <div
                            class="invalid-feedback"
                            data-sb-feedback="emailAddress:email"
                          >
                            Email Address Email ist ungültig.
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="form-label" for="emailAddress"
                            >Telefon</label
                          >
                          <input
                            class="form-control"
                            type="text"
                            name="telefon"
                            placeholder="03671 12 34 56"
                            v-model="mail.telefon"
                            v-bind:class="background.telefon"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- Message input -->
                    <div class="mb-3">
                      <label class="form-label" for="message">Anfrage*</label>
                      <textarea
                        class="form-control"
                        id="message"
                        type="text"
                        placeholder="Ihr Terminwunsch"
                        style="height: 10rem"
                        v-model="mail.message"
                        v-bind:class="background.message"
                        required
                      ></textarea>
                      <div
                        class="invalid-feedback"
                        data-sb-feedback="message:required"
                      >
                        Message ist notwendig.
                      </div>
                    </div>

                    <!-- Form submissions success message -->
                    <div class="d-none" id="submitSuccessMessage">
                      <div class="text-center mb-3">
                        Formular wurde gesendet!
                      </div>
                    </div>

                    <!-- Form submissions error message -->
                    <div class="d-none" id="submitErrorMessage">
                      <div class="text-center text-danger mb-3">
                        Fehler beim Senden des Formulars!<br />
                        Sie können uns auch unter der Rufnummer: 03671 / 64 13
                        11 erreichen
                      </div>
                    </div>

                    <div class="col-md-12 mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="andere"
                        v-model="mail.datenschutz"
                        v-bind:class="background.datenschutz"
                        required
                      />
                      <label class="form-check-label" for="andere"
                        >&nbsp; Ich habe die
                        <a href="/Datenschutz">Datenschutzerklärung</a> zur
                        Kenntnis genommen.
                      </label>
                      <label class="form-check-label inputerror"> </label>
                    </div>

                    <!-- Form submit button -->
                    <div class="d-grid">
                      <button
                        class="btn btn-primary btn-lg"
                        type="submit"
                        @click="sendMail()"
                      >
                        Senden
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-6 text-center">
                        <div class="row ms-3 me-3 mb-0 pb-0">
                          <div
                            class="col-12 mb-0 pb-0 h2 text-Weiss text-Shadow fw-bold"
                          >
                            ARZTPRAXIS
                          </div>
                        </div>
                        <div class="row pb-0">
                          <div class="col-12 mb-0 pb-0 h4 text-Rot fw-bold">
                            ALMUT BOTSCH
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 praxisFfA text-Dunkelgrau">
                            Fachärztin für Allgemeinmedizin
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 text-end">
                        <div class="row">
                          <div class="col-md-10">
                            Rathenaustr.11 <br />
                            07318 Saalfeld
                          </div>
                          <div class="col-md-2">
                            <i
                              class="fas fa-map-marker-alt"
                              style="font-size: 18px"
                            ></i>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-10">
                            <a class="text-Schwarz" href="tel:03671 641311"
                              >03671 / 64 13 11</a
                            >
                          </div>
                          <div class="col-md-2">
                            <i class="fas fa-phone" style="font-size: 18px"></i>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-10">03671 / 64 45 05</div>
                          <div class="col-md-2">
                            <i class="fas fa-print" style="font-size: 18px"></i>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-10">
                            <a
                              class="text-Schwarz"
                              href="mailto:praxis-botsch-slf@web.de"
                              >praxis-botsch-slf@web.de</a
                            >
                          </div>
                          <div class="col-md-2">
                            <i
                              class="fas fas fa-envelope"
                              style="font-size: 18px"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-Dunkelgrau">
          <div class="col-md-10 offset-md-1 mt-5 mb-5 pb-4 bg-Hellgrau">
            <div class="row">
              <div class="col-md-12 kastengrau">
                <h1 class="fw-bold text-Dunkelgrau text-uppercase">
                  UNSERE ÖFFNUNGSZEITEN
                </h1>
                <p></p>
              </div>
            </div>
            <div class="row fw-bold text-Weiss fuerGross">
              <div class="col-2 ms-5">
                <h3>Montag</h3>
                <h3>Dienstag</h3>
                <h3>Mittwoch</h3>
                <h3>Donnerstag</h3>
                <h3>Freitag</h3>
              </div>
              <div class="col-2 ms-5">
                <h3>7 - 11 Uhr</h3>
                <h3>7 - 11 Uhr</h3>
                <h3>7 - 11 Uhr</h3>
                <h3>--------</h3>
                <h3>7 - 11 Uhr</h3>
              </div>

              <div class="col-2 ms-5">
                <h3>15 - 18 Uhr</h3>
                <h3>--------</h3>
                <h3>--------</h3>
                <h3>15 - 18 Uhr</h3>
                <h3>--------</h3>
              </div>
            </div>
            <div class="row fw-bold text-Weiss fuerKlein">
              <div class="col-12">
                <h3 style="text-align: center">
                  <p>Montag</p>
                  <p>7 - 11 Uhr & 15 - 18 Uhr</p>
                  <p>- - -</p>
                  <p>Dienstag</p>
                  <p>7 - 11 Uhr</p>
                  <p>- - -</p>
                  <p>Mittwoch</p>
                  <p>7 - 11 Uhr</p>
                  <p>- - -</p>
                  <p>Donnerstag</p>
                  <p>15 - 18 Uhr</p>
                  <p>- - -</p>
                  <p>Freitag</p>
                  <p>7 - 11 Uhr</p>
                </h3>
              </div>
            </div>
            <div class="row fw-bold kastengrau">
              <div
                class="col-md-10 ms-5 border-bottom border-3 border-dark"
              ></div>
            </div>
            <div class="row">
              <div class="col-md-12 m-5">
                <h1 class="fw-bold text-Dunkelgrau text-uppercase">
                  Wir machen Urlaub
                </h1>
                <p></p>
              </div>
            </div>


            <div class="row fw-bold text-Weiss fuerGross">
              <div class="col-2 ms-5">
                <h3>KW 07</h3>
              </div>
              <div class="col-9 ms-5">
                <h3>vom 12.02.24 - 16.02.24</h3>
              </div>
            </div>
            <div class="row fw-bold text-Weiss fuerKlein">
              <div class="col-12 ms-5">
                <h3>KW 07</h3>
              </div>
              <div class="col-12 ms-5">
                <h3>vom 12.02.24 - 16.02.24</h3>
              </div>
            </div>

            <div class="row fw-bold text-Weiss fuerGross">
              <div class="col-2 ms-5">
                <h3>KW 14</h3>
              </div>
              <div class="col-9 ms-5">
                <h3>vom 02.04.24 - 05.04.24</h3>
              </div>
            </div>
            <div class="row fw-bold text-Weiss fuerKlein">
              <div class="col-12 ms-5">
                <h3>KW 14</h3>
              </div>
              <div class="col-12 ms-5">
                <h3>vom 02.04.24 - 05.04.24</h3>
              </div>
            </div>

            <div class="row fw-bold text-Weiss fuerGross">
              <div class="col-2 ms-5">
                <h3>KW 29 - 31</h3>
              </div>
              <div class="col-9 ms-5">
                <h3>vom 15.07.24 - 02.08.24</h3>
              </div>
            </div>
            <div class="row fw-bold text-Weiss fuerKlein">
              <div class="col-12 ms-5">
                <h3>KW 29 - 31</h3>
              </div>
              <div class="col-12 ms-5">
                <h3>vom 15.07.24 - 02.08.24</h3>
              </div>
            </div>

          <div class="row fw-bold text-Weiss fuerGross">
            <div class="col-2 ms-5">
              <h3>KW 41</h3>
            </div>
            <div class="col-9 ms-5">
              <h3>vom 07.10.24 - 11.10.24</h3>
            </div>
          </div>
          <div class="row fw-bold text-Weiss fuerKlein">
            <div class="col-12 ms-5">
              <h3>KW 41</h3>
            </div>
            <div class="col-12 ms-5">
              <h3>vom 07.10.24 - 11.10.24</h3>
            </div>
          </div>

          <div class="row fw-bold text-Weiss fuerGross">
            <div class="col-2 ms-5">
              <h3>KW 51</h3>
            </div>
            <div class="col-9 ms-5">
              <h3>vom 16.12.24 - 20.12.24</h3>
            </div>
          </div>
          <div class="row fw-bold text-Weiss fuerKlein">
            <div class="col-12 ms-5">
              <h3>KW 51</h3>
            </div>
            <div class="col-12 ms-5">
              <h3>vom 16.12.24 - 20.12.24</h3>
            </div>
          </div>

          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import axios from "axios";

export default {
  name: "Termine",

  data() {
    return {
      newSite: null,
      mail: {
        name: "",
        email: "",
        message: "",
        telefon: "",
        datenschutz: false,
        gesendet: "",
      },
      background: {
        name: "",
        email: "",
        message: "",
        telefon: "",
        datenschutz: "",
      },
    };
  },
  components: {
    Layout,
  },
  methods: {
    checkEingaben() {
      let re = true;
      if (this.mail.name == "") {
        this.background.name = "border border-2 border-danger";
        re = false;
      } else {
        this.background.name = "border border-2 border-success";
      }
      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }
      if (this.mail.message == "") {
        this.background.message = "border border-2 border-danger";
        re = false;
      } else {
        this.background.message = "border border-2 border-success";
      }
      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },
    sendMail() {
      if (this.checkEingaben()) {
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "praxis-botsch-slf@web.de",
          Name: this.mail.name,
          Email: this.mail.email,
          Grund: "Terminanfrage",
          Text: this.mail.message,
          Datenschutz: this.mail.datenschutz,
        };
        let mail = this.mail;
        let background = this.background;
        axios
          .post("https://pillar.de/kito/php/post.php", payload, axiosConfig)
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Ihre Kontaktdaten wurden erfolgreich versendet";
            mail.name = "";
            background.name = "";
            mail.email = "";
            background.email = "";
            mail.message = "";
            background.message = "";
            mail.datenschutz = false;
            background.datenschutz = "";
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
    },
  },
  computed: {},
};
</script>

<style scoped></style>
