<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0 m-0">
        <div class="row praxisBackground text-Dunkelgrau">
          

          <div class="col-md-8 offset-md-2 mb-5 mt-5 align-items-center ps-5">
            <h1 class="mt-5">Impressum</h1>
            <h2 class="mt-5">Angaben nach § 5 TMG:</h2>
            <p>Almut Botsch <br />Fachärztin für Allgemeinmedizin</p>
            <p>Rathenaustraße 11 <br />07318 Saalfeld</p>

            <p>Tel.: <a href="tel:+49 3671 64 13 11">+49 3671 64 13 11</a> <br />Fax: +49 3671 614 505</p>

            <p>
              E-Mail: <a
              title="Mail an praxis-botsch-slf@web.de schreiben"
              href="mailto:praxis-botsch-slf@web.de"
              target="_blank"
              rel="noopener"
              >praxis-botsch-slf@web.de</a>
               <br />Web:
              <a href="www.praxis-botsch-slf.de">www.praxis-botsch-slf.de</a>
            </p>

            <h3>Aufsichtsbehörde:</h3>
            <p>
              Kassenärztliche Vereinigung Thüringen <br />
              Zum Hospitalgraben 8 <br />
              99425 Weimar<br />
             <a href="www.kv-thueringen.de">www.kv-thueringen.de</a> 
            </p>
            <p>
              Landesärztekammer Thüringen <br />
              Im Semmicht 33 <br />
              07751 Jena<br />
             <a href="www.laek-thueringen.de">www.laek-thueringen.de</a> 
            </p>

            <h3>Berufsbezeichnung und berufsrechtliche Regelungen:</h3>
            <p>
              Berufsbezeichnung: Facharzt für Allgemeinmedizin <br />
              Zuständige Kammer: Landesärztekammer Thüringen <br />
              Verliehen durch: Bundesrepublik Deutschland
            </p>

            <h3>Haftungsausschluss:</h3>
            <p>
              Die bereitgestellten Informationen sind in keiner Form Ersatz für
              eine professionelle Behandlung oder Beratung durch einen
              ausgebildeten Facharzt. Die Inhalte dienen nicht der Erstellung
              eigenständiger Diagnosen oder der Auswahl bzw. der Anwendung von
              Behandlungsmethoden. Die Praxis kann, weder direkt noch indirekt,
              für Schäden oder Unannehmlichkeiten, durch den Gebrauch der
              Informationen auf dieser Webseite zur Verantwortung gezogen
              werden.
            </p>
            <p>
              Es wird vom Webseiteninhaber keine Haftung für die Inhalte von
              ggf. angegebenen externen Links übernommen. Für den Inhalt der
              verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich.
            </p>
            <p>
              Die Inhalte auf der Webseite werden regelmäßig überprüft und sind
              demnach auf dem jeweiligen aktuellen Stand. Der Webseiteninhaber
              behält sich vor, ohne Vorankündigung eingestellten Daten und
              Informationen zu bearbeiten und zu aktualisieren. Es keine Haftung
              oder Garantie für den aktuellen Stand Aktualität, Vollständigkeit
              oder die Korrektheit der bereitgestellten Informationen übernommen
              werden.
            </p>
            <p class="fw-bold">
              Hinweis gem. Art. 14 Abs. 1 der EU-Verordnung (EU) Nr. 524/2013
              (Verordnung über Online-Streitbeilegung in
              Verbraucherangelegenheiten):
            </p>
            <p>
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:
              <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a> Unsere E-Mail-Adresse finden
              Sie oben im Impressum.
            </p>
            <p>
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen
            </p>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Impressum",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
