<template>
  
  
    <div class="row position-relative">
      <div class="col-md-3  pt-3  text-center">
       <img src="../assets/Logo_Praxis.png" class="logoObenLinks" alt="..." />
      </div>
      <div class="col-md-9 ">
        <ul
          class="nav header_Justify border-bottom border-danger border-2 fw-bold"
        >
          <li class="nav-item active">
            <router-link
              to="/"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/' ? 'nav-active' : 'nav-deactiv',
              ]"
              >Start</router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Praxis"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Praxis' ? 'nav-active' : 'nav-deactiv',
              ]"
              >Praxis</router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Leistungen"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Leistungen' ? 'nav-active' : 'nav-deactiv',
              ]"
              >Leistungen</router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Termine"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Termine' ? 'nav-active' : 'nav-deactiv',
              ]"
              >Termine</router-link
            >
          </li>
          <li class="nav-item active">
            <router-link
              to="/Kontakt"
              class="nav-link"
              v-bind:class="[
                activeScreen == '/Kontakt' ? 'nav-active' : 'nav-deactiv',
              ]"
              >Kontakt</router-link
            >
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
  methods: {},
  data() {
    return {};
  },
  created() {},
};
</script>

<style scoped></style>
