<template>
  <Layout>
    <template #default>
      <div class="container-fluid p-0">
        <div class="row bg-Weiss">
          <div class="col-md-6 offset-md-3 text-end pt-md-5 mt-md-5">
            <img
              src="@/assets/gruppe.jpg"
              class="img-fluid mx-auto d-block"
              v-bind:alt="pic"
            />
          </div>
        </div>

        <div class="row bg-Dunkelgrau">
          <div class="col-md-10 offset-1 mt-5">
            <h1 class="text-Weiss fw-bold">UNSERE LEISTUNGEN</h1>
          </div>
        </div>
        <div class="row bg-Dunkelgrau">
          <div class="col-md-10 offset-md-1 bg-Dunkelgrau">
            <div class="row bg-Dunkelgrau">
              <div class="col-md m-md-5 bg-Hellgrau center bildKasten">
                <img
                  src="@/assets/sz1.jpg"
                  class="img-fluid imgRoundet mx-auto d-block"
                  v-bind:alt="Bild_von_Bruno_ / Germany_auf_Pixabay"
                />
              </div>
              <div class="col-md kastengrau bg-Hellgrau height=100%">
                <div class="bg-Hellgrau textgrau">
                  <h1 class="fw-bold text-Dunkelgrau">Basisleistungen</h1>
                  <p class="pt-0 kastentext">
                    Unsere Hausarztpraxis in Saalfeld-Gorndorf ist gerne Ihr
                    erster Ansprechpartner bei sämtlichen gesundheitlichen
                    Problemen und Fragen. Als Praxis für Allgemeinmedizin
                    behandeln wir Patienten ab vollendetem 16. Lebensjahr unter
                    Berücksichtigung der biologischen, psychischen und sozialen
                    Dimension ihrer individuellen gesundheitlichen Leiden. Bei
                    Bedarf überweisen wir Sie an die fachärztlichen Kollegen.
                    Uns ist dabei ein respektvolles Verhältnis auf
                    Vertrauensbasis besonders wichtig. Wir haben ein offenes Ohr
                    für alle ihre Anliegen. Jeder Mensch ist anders – diesem
                    Grundsatz folgen wir jederzeit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-Dunkelgrau">
          <div class="col-md-10 offset-md-1 bg-Dunkelgrau">
            <div class="row bg-Dunkelgrau">
              <div class="col-md kastengrau bg-Hellgrau height=100%">
                <div class="bg-Hellgrau textgrau">
                  <h1 class="fw-bold text-Dunkelgrau">Vorsorge</h1>
                  <p class="pt-0 kastentext">
                    Durch Vorsorgeuntersuchungen lassen sich chronische
                    Erkrankungen frühzeitig erkennen und schwere Verläufe
                    vorbeugen. Wir bieten Check-up Untersuchungen einmalig vor
                    dem 35. Lebensjahr und danach aller 3 Jahre an. Bei
                    bekannten chronischen Erkrankungen und regelmäßiger
                    Medikamenteneinnahme erfolgen regelmäßige Kontrollen in der
                    Sprechstunde und mittels Laborwerte. Darüber hinaus
                    überprüfen wir regelmäßig den Impfstatus unserer Patienten,
                    um Infektionserkrankungen vorzubeugen.
                  </p>
                </div>
              </div>

              <div class="col-md m-md-5 bg-Hellgrau center bildKasten">
                <img
                  class="img-fluid imgRoundet mx-auto d-block"
                  src="@/assets/sz2.jpg"
                  v-bind:alt="pic"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-Dunkelgrau">
          <div class="col-md-10 offset-md-1 bg-Dunkelgrau">
            <div class="row bg-Dunkelgrau">
              <div class="col-md m-md-5 bg-Hellgrau center bildKasten">
                <img
                  src="@/assets/labor.jpg"
                  class="img-fluid imgRoundet mx-auto d-block"
                  v-bind:alt="Bild_von_Gerd_Altmann_auf_Pixabay"
                />
              </div>
              <div class="col-md kastengrau bg-Hellgrau height=100%">
                <div class="bg-Hellgrau textgrau">
                  <h1 class="fw-bold text-Dunkelgrau">DMP</h1>
                  <p>Disease-Management-Programme</p>
                  <p class="pt-0 kastentext">
                    Die Praxis nimmt am strukturierten Behandlungsprogramm des
                    Diabetes mellitus Typ 2 teil. Hierbei koordinieren wir die
                    Behandlung mit den verschiedenen Fachärzten, wie
                    Diabetologie, Augenheilkunde und Gefäßchirurgie. Es erfolgt
                    eine zentrale Auswertung aller DMPs, um so die medizinische
                    Versorgung weiter zu verbessern. Aus diesem Grund ist eine
                    Einschreibung notwendig.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Praxis",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
