<template>
  <Layout>
    <template #default>
      <div class="">
        <div class="container-fluid m-0 p-0">
          <div class="row bgPicArtz ps-5">
            <div class="col-12">
              <div class="row mt-5 ms-5 mb-0 pb-0">
                <div
                  class="col-12 mb-0 pb-0 h1 text-Hellgrau text-Shadow fw-bold"
                >
                  &nbsp;
                </div>
              </div>
              <div class="row mb-0 ms-5 pb-0">
                <div class="col-12 mb-0 pb-0 h2 text-Rot fw-bold">&nbsp;</div>
              </div>
              <div class="row ms-5">
                <div class="col-12 h6 text-Dunkelgrau">&nbsp;</div>
              </div>
              <div class="row mt-5 mb-5 ms-5">
                   <img src="../assets/NehmenSiePlatz.png" 
                        class="nehmenSiePlatz" 
                        alt="..." 
                        />
     
              </div>
            </div>
          </div>
          <div class="row bg-Hellgrau">
            <div class="col-12 text-Dunkelgrau">
              <div class="row">
                <div class="col-md-10 offset-md-1">
                  <div class="row">
                    <div class="col-md-4 text-Weiss lh-sm">
                      <div class="m-2 p-4 bg-Dunkelgrau" style="height: 100%">
                        <p class="text-center">
                          <i class="far fa-clock" style="font-size: 36px"></i>
                        </p>
                        <p class="text-center">
                          Unsere Öffnungszeiten<br />
                          ____________________
                        </p>

                        <div class="row fw-bold" style="font-size: 16px">
                          <div class="col-4 lh-1">
                            <p>Montag</p>
                            <p>Dienstag</p>
                            <p>Mittwoch</p>
                            <p>Donnerstag</p>
                            <p>Freitag</p>
                          </div>
                          <div class="col-4 lh-1">
                            <p>7 - 11 Uhr</p>
                            <p>7 - 11 Uhr</p>
                            <p>7 - 11 Uhr</p>
                            <p>--------</p>
                            <p>7 - 11 Uhr</p>
                          </div>

                          <div class="col-4 lh-1">
                            <p>15 - 18 Uhr</p>
                            <p>--------</p>
                            <p>--------</p>
                            <p>15 - 18 Uhr</p>
                            <p>--------</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 text-Weiss lh-sm">
                      <div class="m-2 p-4 bg-HellGruen" style="height: 100%">
                        <p class="text-center">
                          <i
                            class="far fa-calendar-alt"
                            style="font-size: 36px"
                          ></i>
                        </p>
                        <p class="text-center">
                          Buchen Sie Ihren Termin!<br />
                          ____________________
                        </p>
                        <div
                          class="badge text-center p-4 bg-Rot fw-bolder"
                          style="width: 100%"
                        >
                          <router-link
                            to="/Termine"
                            style="font-size: 16px"
                            class="text-Weiss fw-bolder m-5"
                            >Terminanfrage</router-link
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 text-Weiss lh-sm">
                      <div class="m-2 p-4 bg-Dunkelgrau" style="height: 100%">
                        <p class="text-center">
                          <i class="fas fa-phone" style="font-size: 36px"></i>
                        </p>
                        <p class="text-center">
                          Kontaktieren Sie uns<br />
                          ____________________
                        </p>
                        <p class="text-center pt-4 fw-bold">
                          <a class="text-Weiss" href="tel:03671 641311"
                            >03671 641311</a
                          >
                          <br /><br />
                          <a
                            class="text-Weiss"
                            href="mailto:praxis-botsch-slf@web.de"
                            >praxis-botsch-slf@web.de</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>

              <div class="row">
                <div
                  class="col-12 bg-Dunkelgrau text-Weiss mt-5 ps-5 pt-4 pb-4 mb-0 h2 fw-bold"
                >
                  UNSERE LEISTUNGEN
                </div>
              </div>
              <div class="row">
                <div class="col-12 bg-Weiss text-center pt-5 pb-4 ps-5">
                  <img
                    src="../assets/gruppe.jpg"
                    class="mx-auto d-block"
                   
                    alt="..."
                    style="
                     max-width: 100%;
                    height: auto;
                    display: block"
                  />
                </div>
              </div>
              <div class="row pt-4 pb-4 bg-Dunkelgrau">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                  <div class="row text-center">
                    <div class="col-md-4 text-Weiss text-center lh-sm">
                      <p class="text-center h3 fw-bold">
                        <router-link
                          to="/Leistungen"
                          class="text-Weiss fw-bolder m-5"
                          >Gesundheit</router-link
                        >
                      </p>
                      <p class="text-center">allgemeine Leistungen</p>
                    </div>
                    <div class="col-md-4 text-Weiss lh-sm">
                      <p class="text-center h3 fw-bold"><router-link
                          to="/Leistungen"
                          class="text-Weiss fw-bolder m-5"
                          >Beratung</router-link
                        ></p>
                      <p class="text-center">individuelle Leistungen</p>
                    </div>

                    <div class="col-md-4 text-Weiss lh-sm">
                      <p class="text-center h3 fw-bold"><router-link
                          to="/Praxis"
                          class="text-Weiss fw-bolder m-5"
                          >Technik</router-link
                        ></p>
                      <p class="text-center">EKG / Sonographie</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2"></div>
              </div>

              <div class="row">
                <div
                  class="col-12 bg-Hellgrau text-Dunkelgrau pt-4 ps-5 mb-0 h3 fw-bold"
                >
                  UNSERE PRAXIS
                </div>
              </div>
              <div class="row d-none d-lg-block">
                <div class="col-md-8 offset-md-2 pb-5">
                  <img
                    src="../assets/aussenPanorama.jpg"
                    class="mx-auto d-block"
                    height="300"
                    alt="..."
                    style="display: block"
                  />
                  <p class="text-center">
                    Wir danken unserer Fotografin Frau <a href="https://www.fotograf-saalfeld.de/">Jana Voigtländer-Zeuner</a> für die schönen
                    Aufnahmen
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
//import carouselHome from "../components/carouselHome.vue";

export default {
  name: "Start",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
    //carouselHome,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
